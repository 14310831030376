import React from 'react'
import MLoadingButton from '@mui/lab/LoadingButton'

function LoadingButton(props) {
	return (
		<>
			<MLoadingButton {...props}></MLoadingButton>
		</>
	)
}

export default LoadingButton
