/* 

All containers are exported here

*/
import Auth from './auth/auth'
import Layout from './layout/layout'
import DashboardPage from './dashboard/dashboardPage'
import AddLead from './leads/addLead/addLead'
import MyLeads from './leads/myLeads/myLeads'
import PreviewLeads from './leads/previewLeads/previewLeads'
import PreviewDocuments from './leads/previewLeads/previewDocuments'
import Members from './members/members.jsx'
import EditMembers from './members/editMembers/editMembers'
import PendingApproval from './pendingApproval/pendingApproval'
import FollowUps from './leads/followUps/followUps'
import UploadLeadsPage from './uploadLeads/uploadLeadsPage'
import LeadUpdatesPage from './leadUpdates/leadUpdatesPage'
import FacebookLeadsPage from './facebookLeads/facebookLeadsPage'
import FacebookFollowUpsPage from './facebookLeads/facebookFollowUpsPage'
// Business Partner Leads
import PartnerLeadListingPage from './partnerLead/partnerLeadListing/partnerLeadListingPage'
import PartnerLeadDetailPage from './partnerLead/partnerLeadDetail/partnerLeadDetailPage'
import PartnerLeadFollowUpsPage from './partnerLead/partnerLeadFollowUps/partnerLeadFollowUpsPage'
import PartnerLeadAddPage from './partnerLead/partnerLeadAdd/partnerLeadAddPage.jsx'
import DoctorActivityPage from './doctorActivity/doctorActivityPage.jsx'
export {
	Auth,
	Layout,
	DashboardPage,
	AddLead,
	MyLeads,
	PreviewLeads,
	PreviewDocuments,
	Members,
	EditMembers,
	PendingApproval,
	FollowUps,
	PartnerLeadListingPage,
	PartnerLeadDetailPage,
	PartnerLeadFollowUpsPage,
	PartnerLeadAddPage,
	UploadLeadsPage,
	LeadUpdatesPage,
	FacebookLeadsPage,
	FacebookFollowUpsPage,
	DoctorActivityPage,
}
